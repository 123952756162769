.DraftEditor-root {
  font-size: 14px;
  font-family: 'Poppins' !important;
  color: '#232B35' !important;
}

.public-DraftStyleDefault-unorderedListItem > div {
  margin: 0.5em 0;
}
.public-DraftStyleDefault-orderedListItem > div {
  margin: 0.5em 0;
}
.public-DraftStyleDefault-ol {
  margin: 0;
}
.public-DraftStyleDefault-ul {
  margin: 0;
}
