.price-container {
  perspective: 1000px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.price-box {
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
  width: 100%;
  height: 100%;
}

/* .annual-checked {
  transform: rotateY(180deg);
} */

.price-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.price-content-back {
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
