.rdw-link-modal-target-option {
  display: none;
}

.rdw-link-modal {
  font-family: 'Poppins';
  box-shadow: none;
}

.rdw-link-modal-btn {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 40px;
  color: #495057;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
  font-family: 'Poppins';
  border-radius: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: -0.43px;
}

.rdw-link-modal-buttonsection {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.rdw-link-modal-buttonsection :first-child {
  background-color: #3171f6;
  color: white;
}
.rdw-link-modal-input {
  font-family: 'Poppins';
  height: 35px;
}

.public-DraftStyleDefault-unorderedListItem > div {
  margin: 0.5em 0;
}
.public-DraftStyleDefault-orderedListItem > div {
  margin: 0.5em 0;
}
.public-DraftStyleDefault-ol {
  margin: 0;
}
.public-DraftStyleDefault-ul {
  margin: 0;
}
